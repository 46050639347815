import React from 'react';
import Layout from '../../../components/Layout';

import ProductCreateEdit from "../../../components/products/ProductCreateEdit";

export default function ProductEditPage( props ) {

    return (
        <Layout allowAnonymous={false}>
            <ProductCreateEdit variant="edit" style={{minWidth:700, width:"60%", marginLeft: "auto", marginRight: "auto"}} id={props.params.productId}/>
        </Layout>
    )
}